import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'normalize.css';

import ArticleEditor from './Admin/ArticleEditor'; 
import ArticleViewer from './Admin/ArticleViewer'; 
import Home from './Home'; 
import Plan from './Plan';
import Construction from './Construction';
import Topic from './Plan/Topic';
import NotFound from './NotFound';
import LessonRenderer from './Lessons/LessonRenderer'
import Videos from './Videos';
import Quizzes from './Quizzes';
import QuizRenderer from './Quizzes/QuizRenderer'
import Games from './Games';
import Avatar from './Avatar';
import Settings from './Settings';
import Trophies from './Trophies';
import Crafts from './Crafts';
import CraftRenderer from './Crafts/CraftRenderer'
import About from './About';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import Contact from './Contact';

// Games
import NoahQuest from './Games/NoahQuest'
import PaintTheWorld from './Games/PaintTheWorld'
import JonahAdventure from './Games/JonahAdventure'
import FeedingFrenzy from './Games/FeedingFrenzy'
import FindOut from './Games/FindOut'
import FindOuts from './Games/FindOuts'
import SpotDifference from './Games/SpotDifference'
import JigsawPuzzles from './Games/JigsawPuzzles'
import LostSheep from './Games/LostSheep'
import WhacASerpent from './Games/WhacASerpent'
import BabelTowerEscape from './Games/BabelTowerEscape'

import RootReducer from '../reducers/RootReducer';

class App extends React.Component {
  render() {
    const store = createStore(RootReducer, {}, applyMiddleware(ReduxThunk));
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Switch> 
            
            <Route exact path="/home" component={Home} /> 
            <Route exact path="/home" component={Home} /> 
            <Route exact path="/plan/:topic" component={Topic} />
            <Route exact path="/plan" component={Plan} />
            <Route exact path="/videos" component={Videos} />
            <Route exact path="/videos/:video" component={Videos} />
            <Route exact path="/quizzes" component={Quizzes} />
            <Route exact path="/builds" component={Crafts} />
            <Route exact path="/builds/:topic" component={CraftRenderer} />
            <Route exact path="/about" component={About} />
            <Route exact path="/terms-of-service" component={TermsOfService} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/contact-us" component={Contact} />

            <Route exact path="/games" component={Games} />
            <Route exact path="/games/noah-quest" component={NoahQuest} />
            <Route exact path="/games/paint-the-world" component={PaintTheWorld} />
            <Route exact path="/games/jonah-adventure" component={JonahAdventure} />
            <Route exact path="/games/find-out" component={FindOuts} />
            <Route exact path="/games/find-out/:game" component={FindOut} />
            <Route exact path="/games/jigsaw-puzzles" component={JigsawPuzzles} />
            <Route exact path="/games/jigsaw-puzzles/:game" component={JigsawPuzzles} />
            <Route exact path="/games/spot-the-difference" component={SpotDifference} />
            <Route exact path="/games/spot-the-difference/:game" component={SpotDifference} />
            <Route exact path="/games/lost-sheep" component={LostSheep} />
            <Route exact path="/games/feeding-frenzy" component={FeedingFrenzy} />
            <Route exact path="/games/whac-a-serpent" component={WhacASerpent} />
            <Route exact path="/games/babel-tower-escape" component={BabelTowerEscape} />

            <Route exact path="/user/avatar" component={Avatar} /> 
            <Route exact path="/user/settings" component={Settings} /> 
            <Route exact path="/user/trophies" component={Trophies} /> 

            <Route exact path="/admin/article-editor" component={ArticleEditor} /> 
            <Route exact path="/admin/article-viewer" component={ArticleViewer} /> 

            <Route exact path="/lessons/:topic" component={LessonRenderer} />
            <Route exact path="/quizzes/:topic" component={QuizRenderer} />

            <Route path="*" component={NotFound} />
          </Switch>
        </BrowserRouter> 
      </Provider>
    );
  }
}

export default App;