import React, { createRef, Component } from 'react';
import { connect } from 'react-redux';
import Unity, { UnityContext } from "react-unity-webgl";
import { isMobile } from "react-device-detect";
import ReactPlayer from 'react-player'
import { MdOutlineFormatLineSpacing } from 'react-icons/md';
import { SpeakerXMarkIcon, SpeakerWaveIcon, ArrowRightOnRectangleIcon, ArrowPathIcon, PauseCircleIcon, ClockIcon } from '@heroicons/react/24/outline'

import GameRuleModal from "./Modals/GameRuleModal"
import GameTitleModal from "./Modals/GameTitleModal"
import WellDoneModal from "./Modals/WellDoneModal"
import GameOverModal from "./Modals/GameOverModal"
import PauseModal from "./Modals/PauseModal"
import GameIntroVideo from '../../elements/GameIntroVideo'
import { buttonSize } from '../../helpers/GameHelper'
import { userFetch, userTrophyFetch, userTrophyCreate } from '../../actions/userActions';
import { trophyConstant } from '../../helpers/ConstantHelper'

import FrameImage from '../../assets/games/frame4.png'
import PauseGif from '../../assets/animations/pause.gif'
import LoadingGif from '../../assets/animations/loading.gif'
import RuleImage from '../../assets/games/babel_escape/game_rule.jpg'
import RuleImageMobile from '../../assets/games/babel_escape/game_rule_mobile.jpg'

import '../../stylesheets/gameFrame.css';

const unityContext = new UnityContext({
 loaderUrl: "builds/babel_escape/Build.loader.js", // public Let's go to the table of contents 
 dataUrl: "builds/babel_escape/Build.data",
 frameworkUrl: "builds/babel_escape/Build.framework.js",
 codeUrl: "builds/babel_escape/Build.wasm",
});

let gameLoop

const actualBgWidth = 1600 
const actualBgHeight = 900 

const actualFrameWidth = 1609
const actualFrameHeight = 1056

class BabelTowerEscape extends React.Component {
  constructor(props) {
    super(props);
    var bgWidth = this.backgroundWidth(window.innerWidth, window.innerHeight)
    this.state = { 
      audioOn: true, 
      gameRule: true,
      wellDoneModal: false,
      gameTitle: true, 
      pause: false,
      intro: true, 
      bgMusic: new Audio('/audio/background/rescue_mission.mp3'), 
      windowWidth: window.innerWidth, 
      windowHeight: window.innerHeight, 
      bgWidth: this.backgroundWidth(window.innerWidth, window.innerHeight), 
      bgHeight: this.backgroundHeight(bgWidth), 
      sizeRate: this.backgroundWidth(window.innerWidth, window.innerHeight)/actualBgWidth, 
      score: 0, 
      time: 0, 
      gameState: "ready", 
      pauseAnimation: PauseGif,
      breathing: false, 
      airLevel: 100, 
      introVidRef: React.createRef(),
      endVidRef: React.createRef(),
      gameOverModal: false, 
      introVideoLoaded: false, 
      unityLoaded: false, 
      level: 0, 
      phone_landscape: false, 
      activeUser: false,
      trophy: null, 
      introVideoStarted: false
    };
  }

  handleWindowSizeChange(){
    if(this.state.windowWidth != window.innerWidth){
      this.resizeCanvas()
    }
  };

  handleIntroPlay(){
    this.setState({introVideoStarted: true})
  }

  handleIntroEnd(){ 
    this.setState({gameState: "ready", gameRule: true, intro: false})
  }

  //prevent zoom in/out on the page
  handleTouchMove(e){ 
    if(e.touches.length > 1){ 
      e.preventDefault()
    }
  }
  handleTouchStart(e){ 
    if(e.touches.length > 1){ 
      e.preventDefault()
    }
  }

  handleContextMenu(e){ 
    if(isMobile){ 
      e.preventDefault()
    }
  }

  handleMouseDown(e){
    this.setState({mouseDown: true})
    //this.props.jonahWhaleUp()
  }

  handleMouseUp(e){
    this.setState({mouseDown: false})
  }

  handleAudioToggle(e){
    // Prevent Spacebar to toggle audio button 
    if(e.screenX == 0){ return }
    var audioOn = !this.state.audioOn
    console.log(audioOn)
    unityContext.send("GameManager", "AudioToggle", audioOn.toString());
    this.setState({audioOn: audioOn })
    if(audioOn == true){ 
      this.state.bgMusic.play()
      //this.state.introVidRef.current.volume = 0.8
    }else{ 
      this.state.bgMusic.pause()
      //this.state.introVidRef.current.volume = 0
      //this.state.endVidRef.current.volume = 0.0
    }
  }
  handlePause(){ 
    if(this.state.gameState == "running"){ 
    unityContext.send("GameManager", "PauseGame");
      this.setState({pause: true})
    }
    this.state.bgMusic.pause();
  }

  handleUnpause(){ 
    if(this.state.gameState == "running"){ 
    unityContext.send("GameManager", "ResumeGame");
    }
    if(this.state.audioOn){ 
      this.state.bgMusic.play();
    }
    this.setState({pauseAnimation: ""})
    setTimeout(() => { 
      this.setState({pause: false})
      this.setState({pauseAnimation: PauseGif})
    }, 0)
  } 

  handleGameOver(win){ 
    unityContext.send("GameManager", "PauseGame");
    this.setState({gameState: "gameOver"})
    clearInterval(gameLoop)
    let trophy = null

    // Let the game over bang effect showing for a while
    setTimeout(() => { 
      // If doesn't pass level 2
      if(this.state.level < 3){ 
        this.setState({gameOverModal: true})
      }
      else{ 
        if(win){ 
          if(!this.props.trophy || this.props.trophy.gold != true){ 
            // Bonus for time
            if(this.state.time < 50){ 
              this.setState({score: this.state.score + 100})
            }else if(this.state.time < 100){ 
              this.setState({score: this.state.score + 50})
            }else if(this.state.time < 150){ 
              this.setState({score: this.state.score + 20})
            }
            trophy = "gold"
          }
        }else if(this.state.level < 6){ 
          if(!this.props.trophy || this.props.trophy.bronze != true){ 
            trophy = "bronze" 
          }
        }else{ 
          if(!this.props.trophy || this.props.trophy.silver != true){ 
            trophy = "silver" 
          }
        }
        this.setState({wellDoneModal: true, trophy: trophy})
        if(this.state.activeUser && trophy){ 
          this.props.userTrophyCreate("game", "babel-escape", trophyConstant[trophy])  
        } 
      }
    }, 700)
  }

  handleLevelStatus(level){ 
    if(level > this.state.level){ 
      this.setState({level: level})
      this.setState({score: this.state.score + 50})
    }
  }

  handleBonusCaptured(){ 
    this.setState({score: this.state.score + 30})
  }

  handleSceneLoaded(){
    this.setState({unityLoaded: true})
    if(isMobile == true){
      unityContext.send("Player", "MobilePlayer");
    }
    if(this.state.gameState == "started"){ 
      const thisObj = this
      setTimeout(function(){ 
        thisObj.startGame()
      }, 100)
    }
  }

  /*
  handleLoadingProgress(percentage){ 
    console.log(`percentage: ${percentage}`)
    if(percentage == 1){ 
      const thisObj = this
      setTimeout(function(){ 
        thisObj.setState({unityLoaded: true})
        if(thisObj.state.gameState == "started"){ 
          thisObj.startGame()
        }
      }, 1800)
      setTimeout(function(){ 
        if(isMobile == true){ 
          unityContext.send("Player", "MobilePlayer");
        }
      }, 1200)
    }
  } */

  handleGameOverComplete(){ 
    this.setState({gameState: "done"})
    this.setState({gameOverModal: false})
    this.handleRestart()
  }

  handleStartPlay(){
    let level = this.state.level
    this.playBackgroundAudio()
    this.setState({gameRule: false, gameState: "started", time: 0})
    if(this.state.unityLoaded){
      this.startGame()
    }
  }

  handleRestart(){ 
    this.setState({level:0, gameState: "started", score: 0, trophy: null, peopleCount: 0, time: 0})
    unityContext.send("GameManager", "ResetGame");
    //unityContext.send("GameManager", "PauseGame");   
    this.state.bgMusic.currentTime = 0
    this.playBackgroundAudio(); 
    const thisObj = this
    setTimeout(function(){ 
      if(thisObj.state.unityLoaded){
        thisObj.startGame()
      }
    }, 200)  
  }

  handleExit(){ 
    this.props.history.goBack()
  }

  handleStartIntro(){
    const video = this.state.introVidRef.current
    this.state.bgMusic.pause()
    video.muted = !this.state.audioOn   
    video.play();
    video.addEventListener('ended', this.handleVideoEnd.bind(this), false)
    this.setState({gameTitle: false})
  }

  handleVideoEnd(){ 
    this.state.bgMusic.currentTime = 0
    this.playBackgroundAudio()
    this.setState({gameRule: true})
  }

  handleWellDoneComplete(){ 
    clearInterval(gameLoop)
    this.setState({wellDoneModal: false})
    let level = this.state.level;
    if(level < 2 && this.state.gameState !="gameOver" ){ 
      level++; 
      //unityContext.send("GameManager", "SetLevel", level);
      this.setState( {gameRule: true, level: level} )
    }
    else{ 
       this.setState({gameState: "done"})
    }
  }

  playBackgroundAudio(){ 
    if(this.state.audioOn == true){ 
      this.state.bgMusic.play()
    }
  }

  transitGameRunning(thisObj){ 
    if(thisObj.state.unityLoaded){ 
      thisObj.startGame()
    }
  }

  startGame(){
    this.setState({gameState: "running"})
    console.log("gameState running");
    unityContext.send("GameManager", "ResumeGame");
    clearInterval(gameLoop)
    gameLoop = setInterval(() => { 
      if(this.state.gameState == "running" && this.state.pause == false)
      { 
        const time = this.state.time + 1
        this.setState({time: time})
      }
    }, 1000) // interval 1s
  }

  resizeCanvas(){ 
    this.handlePause()
    this.setState({pauseTime: new Date().getTime()})
    var bgWidth = this.backgroundWidth(window.innerWidth, window.innerHeight)
    var bgHeight = this.backgroundHeight(bgWidth)
    var sizeRate = bgWidth/actualBgWidth
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    this.setState({ bgWidth: bgWidth, bgHeight:bgHeight });
    this.setState({sizeRate: bgWidth/actualBgWidth})    
  }

  componentDidUpdate(prevProps) {
    if(this.props.userLoaded != prevProps.userLoaded){ 
      // Only fetch trophies if user is login 
      if(Object.keys(this.props.user).length){ 
        this.setState({activeUser: true, gameState:"ready", gameRule: true})
        this.props.userTrophyFetch("game", "feeding-frenzy")
      }
    }
  }
   
  componentDidMount() {
    unityContext.on("GameOver", this.handleGameOver.bind(this))
    unityContext.on("LevelStatus", this.handleLevelStatus.bind(this))
    unityContext.on("BonusCaptured", this.handleBonusCaptured.bind(this))
    unityContext.on("SceneLoaded", this.handleSceneLoaded.bind(this))
    //unityContext.on("progress", this.handleLoadingProgress.bind(this)) 
    var bgWidth = this.backgroundWidth(window.innerWidth, window.innerHeight)
    var bgHeight = this.backgroundHeight(bgWidth)
    document.addEventListener('dragstart', event=>event.preventDefault())
    window.addEventListener('contextmenu', this.handleContextMenu.bind(this))
    window.addEventListener('resize', this.handleWindowSizeChange.bind(this));
    window.addEventListener('blur', this.handlePause.bind(this));
    window.addEventListener('touchmove', this.handleTouchMove.bind(this), {passive:false} );
    window.addEventListener('touchstart', this.handleTouchStart.bind(this), {passive:false} );
    this.state.bgMusic.addEventListener('ended', () => this.playBackgroundAudio())
    this.setState({sizeRate: bgWidth/actualBgWidth})   
    this.props.userFetch();
  
    window.scrollTo(0, 0);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange.bind(this));
    window.removeEventListener('contextmenu', this.handleContextMenu.bind(this));
    window.removeEventListener('blur', this.handlePause.bind(this));
    window.removeEventListener('touchmove', this.handleTouchMove.bind(this));
    window.removeEventListener('touchstart', this.handleTouchStart.bind(this));

  }

  backgroundWidth(width, height){ 
    if(width/height > (actualFrameWidth/actualFrameHeight)){ 
      if(isMobile){ 
        this.setState( {phoneLandscape: true}) 
      }
      return Math.min(1800, 0.9 * height * actualFrameWidth/actualFrameHeight)
    }else{ 
    if(width > 1280){ 
      return 0.7 * width 
    }else{ 
      return 0.98 * width
    }
    }   
  }

  paddingClass(){ 
    if(window.innerWidth/window.innerHeight > actualFrameWidth/actualFrameHeight){ 
      return "py-0 lg:py-10"
    }else{ 
      return "py-5 lg:py-10"
    }
  }

  backgroundHeight(width){ 
   var height = parseFloat(width) * 9 / 16
    return (parseFloat(width) * 9 / 16)
  }

  pauseModal(){
    if(this.state.pause == true){ 
      return <PauseModal 
              buttonSize = {buttonSize(this.state.windowWidth, this.state.windowHeight)}
              pauseGif = {this.state.pauseAnimation}
              audioToggleHandler= {this.handleAudioToggle.bind(this)}
              unpauseHandler = {this.handleUnpause.bind(this)}
              exitHandler = {this.handleExit.bind(this)}
              open = { this.state.pause }
              audio = {this.state.audioOn}
            />
    }
    return <div/>
  }

  volumeIcon(width){ 
    if(this.state.audioOn == true){ 
      return <SpeakerWaveIcon className="mx-auto text-white" style={{height: `${width}px`, width: `${width}px`}} aria-hidden="true"/>
    }else{
      return <SpeakerXMarkIcon className="mx-auto text-white"  style={{height: `${width}px`, width: `${width}px`}} aria-hidden="true"/>
    }
  }

  timeLapsed(){ 
    var minute = parseInt(this.state.time/60) 
    var second = (this.state.time % 60) + ""
    if(second.length < 2){ 
      second = "0" + second
    }
    return `${minute}:${second}`
  }

  introVideo(){ 
    if(this.state.intro == true){ 
      return(
        <div className="z-50 m-auto" 
          style={{position: 'absolute', 
                top: `${164 * this.state.sizeRate}px`,
                left: `${81 * this.state.sizeRate}px`,
                width: `${1920 * this.state.sizeRate}px`,
                height: `${1080 * this.state.sizeRate}px`,
          }}
        >  
          <GameTitleModal
            open = { this.state.gameTitle }
            audioToggleHandler = { this.handleAudioToggle.bind(this)}
            startPlayHandler = { this.handleStartIntro.bind(this)}
            audio = { this.state.audioOn }
          />
          <video style={{width:`${1600 * this.state.sizeRate}px`}} 
            controls
            ref={this.state.introVidRef}>
            <source src={`/videos/jonahIntro.mp4`} type="video/mp4" />
            onEnded={() => alert("ended")}
          </video>
        </div>
      )
    }
  }

  gameOverModal(){
    if(this.state.gameOverModal==true){ 
      return( 
        <GameOverModal
          open = { true }
          buttonSize = {buttonSize(this.state.windowWidth, this.state.windowHeight)}
          endHandler = { this.handleGameOverComplete.bind(this)}
        />
      )
    }
    return <div/>
  }

  doneDiv(){ 
    if(this.state.gameState == "done"){ 
      return(
        <div> 
          {/* End Video 
          <div className="p-4 flex justify-center justify-items-center mx-auto"> 
            <video style={{width:`${1600 * this.state.sizeRate}px`}} ref={this.state.endVidRef}>
              <source src={`/videos/jonahEnd.mp4`} type="video/mp4" />
            </video>
          </div>
          */}
          <div className="mx-auto justify-items-center mt-2 grid grid-cols-2"
            style={{width:`${1600 * this.state.sizeRate}px`}} 
          >
            <button className="w-28 md:w-32 fancy-button orange grid grid-cols-2"
              onClick={(e) => window.location.href = "/games" } 
            >
              <span> 
                <ArrowRightOnRectangleIcon className="h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"
                  style={{transform: "rotateY(180deg)"}} 
                />
              </span> 
              <span className="my-auto hidden md:block">
                Exit
              </span>
             
            </button>
            <button className="w-28 md:w-32 fancy-button green grid grid-cols-2"
              onClick={ this.handleRestart.bind(this) }
            >
              <span> 
                <ArrowPathIcon className="h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"/> 
              </span> 
              <span className="my-auto hidden md:block">
                Play Again
              </span>
            </button>
          </div>
        </div> 
      )
    }
  }

  gameRuleModal(){ 
    let level = this.state.level + 1
    let heading = "" 
    if(level == 1){ 
      heading = "Imagine yourself at the abandoned Tower of Babel. " 
    }
    let ruleImage = RuleImage; 
    if(isMobile){ 
      ruleImage = RuleImageMobile;
    }
    return( 
      <GameRuleModal
        open = { this.state.gameRule }
        audioToggleHandler = { this.handleAudioToggle.bind(this)}
        startPlayHandler = { this.handleStartPlay.bind(this)}
        audio = { this.state.audioOn }
        phoneLandscape = { this.state.phoneLandscape }
        thumbnail = { ruleImage }
        heading = { heading }
        description = { "" }
        instructions = { ["Avoid any obstacle and leave the tower.", "Collect tool items for bonus points."] }
      />
    )
  }

  wellDoneModal(){
    if(this.state.wellDoneModal==true){ 
      return( 
        <WellDoneModal
          open = { true }
          score = { this.state.score }
          trophy = { this.state.trophy }
          searchParams = { '?tab=game'}
          game = "Jonah Adventure"
          activeUser = { this.state.activeUser }
          endHandler = { this.handleWellDoneComplete.bind(this)}
        />
      )
    }
    return <div/>
  }

  loadingDiv(){ 
    if(!this.state.unityLoaded){ 
      return(
        <div className={`z-40`}
            style = {{position: 'absolute',    
                      top: `${150 * this.state.sizeRate}px`,
                      left: `${4 * this.state.sizeRate}px`,
                      width: `${this.state.bgWidth}px`,
                      height: `${this.state.bgHeight}px`,
                      background: 'black',
                      overflow: 'hidden'}} 
        >
          {this.loadingAnim()}
        </div>
      )
    }
  }

  loadingAnim(){ 
    if(!this.state.gameRule){ 
      return(
        <div className="flex items-center justify-center h-full"> 
          <img className="w-1/2"
            src="/games/images/loading.gif"
            alt=""
          />
        </div>
      )
    }
  }



  introVideoDiv(){ 
    if(this.props.userLoaded && !this.state.activeUser){ 
      return(
        <GameIntroVideo
          introEndHandler = { this.handleIntroEnd.bind(this)}
          introPlayHandler = { this.handleIntroPlay.bind(this)}
          introTextEnabled = { !this.state.introVideoStarted && this.props.userLoaded }
          videoId = { "7B_Y1GqIMY8" }
        />
      )
    }
  }

  gameDiv(){ 
    if(this.state.gameState == "pending"){ 
      return this.introVideoDiv()
    }
    return( 
      <div> 
        {this.pauseModal()}
        {this.gameOverModal()}
        {this.gameRuleModal()}
        {this.wellDoneModal()}
        <div className="bg-yellow mx-auto my-auto"
          style={{ 
            position: 'relative',
            width: `${actualFrameWidth * this.state.sizeRate}px`,
            height: `${actualFrameHeight * this.state.sizeRate}px`,
          }}
        > 
          <div className={`z-20 m-auto`}
            style={{position: 'absolute', 
                  top: `0px`,
                  left: `0px`,
                  width: `${actualFrameWidth * this.state.sizeRate}px`,
                  height: `${actualFrameHeight * this.state.sizeRate}px`,
            backgroundImage: `url(${FrameImage})`,
            backgroundSize: `${actualFrameWidth * this.state.sizeRate}px ${actualFrameHeight * this.state.sizeRate}px`, 
            backgroundRepeat: 'no-repeat'
            }}
          >  
            <button class="focus:outline-none fancy-round-button green"
              style={{position: 'absolute', 
                top: `${20 * this.state.sizeRate}px`,
                left: `${1352 * this.state.sizeRate}px`,
                width: `${100 * this.state.sizeRate}px`,
                height: `${105 * this.state.sizeRate}px`,
              }}
              onClick={this.handleAudioToggle.bind(this)}                
            >
              {this.volumeIcon(100 * this.state.sizeRate )}
            </button>
            <button className="focus:outline-none fancy-round-button red"
              style={{position: 'absolute', 
                top: `${20 * this.state.sizeRate}px`,
                left: `${1485 * this.state.sizeRate}px`,
                width: `${100 * this.state.sizeRate}px`,
                height: `${105 * this.state.sizeRate}px`,
              }}
              onClick={this.handlePause.bind(this)}
            >
              <p className="text-white font-black" 
                style={{height: `${100 * this.state.sizeRate}`, width: `${100 * this.state.sizeRate}`}} > 
                <PauseCircleIcon className="mx=auto"/>
              </p>
            </button>
            <div
              style = {{
                position: 'absolute',    
                top: `${5 * this.state.sizeRate}px`,
                left: `${30 * this.state.sizeRate}px`,
                width: `${350 * this.state.sizeRate}px`, 
                height: `${70 * this.state.sizeRate}px`,
                display: 'inline-block', 
                overflow: 'hidden'}}
            > 
              <div className="grid grid-cols-2">           
                <ClockIcon className="mx-auto text-white" 
                  style={{
                    width: `${70 * this.state.sizeRate}px`, 
                    height: `${70 * this.state.sizeRate}px`,
                  }} 
                  aria-hidden="true"
                />
                <div>
                  <p className="text-right frame-font text-white unselectable"
                    style = {{ lineHeight: `${70 * this.state.sizeRate}px`, fontSize: `${60 * this.state.sizeRate}px` }}
                  >
                    {this.timeLapsed()}
                  </p>
                </div>
              </div>              
            </div> 
            {/* Score */}
            <div className="unselectable flex"
              style = {{
                position: 'absolute',    
                top: `${0 * this.state.sizeRate}px`,
                left: `${676 * this.state.sizeRate}px`,
                width: `${250 * this.state.sizeRate}px`, 
                height: `${70 * this.state.sizeRate}px`,
                display: 'inline-block', 
                overflow: 'hidden'}}
            > 
              <div className="h-full m-auto">
                <p className="text-center game-desc text-white"
                  style = {{ lineHeight: `${70 * this.state.sizeRate}px`, fontSize: `${60 * this.state.sizeRate}px` }}
                >
                  Score  
                </p>  
              </div>      
            </div> 
            <div
              style = {{
                position: 'absolute',    
                top: `${65 * this.state.sizeRate}px`,
                left: `${676 * this.state.sizeRate}px`,
                width: `${250 * this.state.sizeRate}px`, 
                height: `${70 * this.state.sizeRate}px`,
                display: 'inline-block', 
                overflow: 'hidden'}}
            > 
              <div className="text-center frame-font text-white unselectable"
                style = {{ fontSize: `${60 * this.state.sizeRate}px` }}
              >
                {this.state.score}
              </div>              
            </div> 
          </div>
          {this.loadingDiv()}
          <div className={`z-30`}
                style = {{position: 'absolute',    
                          top: `${150 * this.state.sizeRate}px`,
                          left: `${4 * this.state.sizeRate}px`,
                          width: `${this.state.bgWidth}px`,
                          height: `${this.state.bgHeight}px`,
                          background: 'transparent',
                          overflow: 'hidden'}}
              onMouseDown={this.handleMouseDown.bind(this)} 
              onMouseUp={this.handleMouseUp.bind(this)} 
              onMouseLeave={this.handleMouseUp.bind(this)} 
              onTouchStart={this.handleMouseDown.bind(this)} 
              onTouchEnd={this.handleMouseUp.bind(this)} 
              onTouchCancel={this.handleMouseUp.bind(this)}>
              <Unity style={{'width': '100%', 'height': '100%'}} unityContext={unityContext} />
          </div>
        </div>
        {this.doneDiv()}
      </div>
    )
  }

  render() {
    return (
      <div> 
        <div className={`mx-auto my-auto grid justify-items-center bg-gradient-to-r from-blue-100 via-pink-200 to-yellow-200 min-w-screen min-h-screen ${this.paddingClass()}`}
          style={{position: 'relative'}}
        >
          {this.gameDiv()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { 
    user: state.user.user,
    userLoaded: state.user.userLoaded,
    trophy: state.user.trophy
  }
};

export default connect(mapStateToProps, { 
  userFetch,
  userTrophyFetch, 
  userTrophyCreate
}) (BabelTowerEscape);