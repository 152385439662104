import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { isMobile } from "react-device-detect";
import Lottie from 'react-lottie';
import { RadioGroup } from '@headlessui/react'
import { SpeakerXMarkIcon, SpeakerWaveIcon, PlayIcon } from '@heroicons/react/24/outline'
import { BiShareAlt } from "react-icons/bi";

import ShareModal from "../../../elements/ShareModal"

function volumeIcon(audio){ 
  if(audio == true){ 
    return <SpeakerWaveIcon className="h-12 w-12 text-white" aria-hidden="true"/>
  }else{
    return <SpeakerXMarkIcon className="h-12 w-12 text-white" aria-hidden="true"/>
  }
}

function gameRuleImage(props){ 
  if(props.thumbnail){ 
    return <img className="mx-auto" src={props.thumbnail} />
  }
  if(props.lottieJson){ 
    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: props.lottieJson,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet'
      }
    };
    return( 
      <Lottie options={defaultOptions} 
        isStopped={false}
        isPaused={false}
      />
    )
  }
}

function templateLayout(props){  
  return( 
    <div> 
      <div className="px-4">
        {gameRuleImage(props)}
      </div>
      <div className="text-center">
        <div>
          <p className="game-desc text-lg md:text-xl text-yellow-200 my-1 mt-2 md:my-2 lg:my-4">
            {props.heading}  
          </p>
          <p className="game-desc text-1xl md:text-2xl text-white my-1 mt-2 md:my-2 lg:my-4">
            {props.title}  
          </p>
          { 
            props.instructions.map((instruction) => { 
              return( 
                <p className="game-desc text-base md:text-lg text-white mb-2">
                  {instruction}
                </p>
              )
            })
          }
        </div>
      </div>
    </div>
  ) 
}

function shareModal(open, handleClose){ 
  let twitterTitle = `Check out this game at BibleFunClub`
  let WhatsappTitle = `Check out this game I am playing-`

  if(open){ 
    return <ShareModal 
      open = { open }
      twitterTitle = { twitterTitle }
      WhatsappTitle = { WhatsappTitle }
      url = { window.location }
      closeHandler = { handleClose }
    />
  }
}

function phoneLandscape(){ 
  if(isMobile && window.orientation != 0 && window.window.innerWidth < 700 ){ // portrait mode orientation = 0
    return true
  }
}

export default function GameRuleModal(props) {
  const [open, setOpen] = useState(true)
  const [shareOpen, setShareOpen] = useState(false)
  const completeButtonRef = useRef()
  function setShareClose(event) { 
    setShareOpen(false)
  }
  return (
    <div>
      { shareModal(shareOpen, setShareClose) }
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-40 inset-0 overflow-y-auto"
          initialFocus={completeButtonRef}
          open={props.open}
          onClose={setOpen}
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className={ `bg-primary-dark inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all 
                              sm:my-8 sm:align-middle max-w-md ${phoneLandscape()?"w-1/2":"sm:w-full"} sm:p-6`}>
                {templateLayout(props)}
                {props.customItems}
                <div className="justify-items-center mt-3 grid grid-cols-2">
                  <button className="w-24 md:w-36 fancy-button orange grid grid-cols-2"
                    onClick={props.audioToggleHandler}
                  >
                    <span className="flex items-center justify-center h-10 w-10 md:h-12 md:w-12 ">
                      {volumeIcon(props.audio)}
                    </span>
                    <span className="my-auto hidden md:block">
                      Audio
                    </span>
                  </button>
                  <button className="w-24 md:w-36 fancy-button green grid grid-cols-2"
                    ref={completeButtonRef}
                    onClick={props.startPlayHandler}
                  >
                    <span> 
                      <PlayIcon className="h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"/> 
                    </span> 
                    <span className="my-auto hidden md:block">
                      Play
                    </span>
                  </button>
                </div>
                <div className="flex justify-center"> 
                  <button className="w-24 md:w-36 fancy-button grid grid-cols-2"
                    onClick={ (e) => {setShareOpen(true)} }
                  >
                    <span> 
                      <BiShareAlt className="h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"/> 
                    </span> 
                    <span className="my-auto hidden md:block">
                      Share
                    </span>
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}